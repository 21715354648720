var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard dashboard-with-banner" },
    [
      _vm._m(0),
      _c(
        "v-container",
        {
          staticClass: "v-main-content stepper-template only-simple-content",
          attrs: { fluid: "" },
        },
        [
          _c("v-row", [
            _c(
              "div",
              { staticClass: "w-100 d-flex flex-column align-center mb-6" },
              [
                _c("h1", { staticClass: "font-weight-bold" }, [
                  _vm._v("Transparent Pricing"),
                ]),
                _c(
                  "p",
                  { staticClass: " text-center mt-4 w-30 font-weight-regular" },
                  [
                    _vm._v(
                      " Our affordable pricing scales with your business. We don’t lock our features behind expensive plans. You get all the features on every plan. "
                    ),
                  ]
                ),
              ]
            ),
          ]),
          [
            _c(
              "v-row",
              { staticClass: "justify-center" },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "3" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "rounded-lg pa-8 h-100",
                        attrs: { elevation: "3" },
                      },
                      [
                        _c("h1", { staticClass: "font-weight-bold" }, [
                          _vm._v("General"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "primary-text d-flex align-center mt-10",
                          },
                          [
                            _c(
                              "h1",
                              { staticClass: "font-weight-bold primary-text" },
                              [_vm._v("$500")]
                            ),
                            _c("span", [_vm._v("per month ")]),
                          ]
                        ),
                        _c(
                          "v-list",
                          { attrs: { dense: "" } },
                          [
                            _c("v-subheader", [
                              _vm._v("Try it as long as you like"),
                            ]),
                            _c(
                              "v-list-item-group",
                              {
                                attrs: { color: "primary" },
                                model: {
                                  value: _vm.selectedItem,
                                  callback: function ($$v) {
                                    _vm.selectedItem = $$v
                                  },
                                  expression: "selectedItem",
                                },
                              },
                              _vm._l(_vm.items, function (item, i) {
                                return _c(
                                  "v-list-item",
                                  { key: i },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", {
                                          domProps: {
                                            textContent: _vm._s(item.icon),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          domProps: {
                                            textContent: _vm._s(item.text),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "secondary ml-4",
                            attrs: { width: "135" },
                            on: {
                              click: function ($event) {
                                return _vm.openPortal()
                              },
                            },
                          },
                          [_vm._v("Buy Now")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "6" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "pa-8 rounded-lg primary h-100",
                        attrs: { elevation: "3" },
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "rounded-lg primary",
                                    attrs: { elevation: "0" },
                                  },
                                  [
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "font-weight-bold white--text",
                                      },
                                      [_vm._v("Enterprise")]
                                    ),
                                    _c(
                                      "h3",
                                      { staticClass: "secondary--text" },
                                      [_vm._v("Starting with...")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "white--text d-flex align-center",
                                      },
                                      [
                                        _c(
                                          "h1",
                                          {
                                            staticClass:
                                              "font-weight-bold white--text",
                                          },
                                          [_vm._v("$500")]
                                        ),
                                        _c("span", [_vm._v("per month ")]),
                                      ]
                                    ),
                                    _c(
                                      "v-list",
                                      {
                                        staticClass: "primary white--text",
                                        attrs: { dense: "" },
                                      },
                                      [
                                        _c(
                                          "v-subheader",
                                          { staticClass: "white--text" },
                                          [_vm._v("Limtless possibilites")]
                                        ),
                                        _c(
                                          "v-list-item-group",
                                          {
                                            attrs: { color: "primary" },
                                            model: {
                                              value: _vm.selectedItem,
                                              callback: function ($$v) {
                                                _vm.selectedItem = $$v
                                              },
                                              expression: "selectedItem",
                                            },
                                          },
                                          _vm._l(_vm.items, function (item, i) {
                                            return _c(
                                              "v-list-item",
                                              {
                                                key: i,
                                                staticClass: "white--text",
                                              },
                                              [
                                                _c(
                                                  "v-list-item-icon",
                                                  [
                                                    _c("v-icon", {
                                                      staticClass:
                                                        "white--text",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          item.icon
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          item.text
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "secondary ml-4",
                                        attrs: { width: "135" },
                                      },
                                      [_vm._v("Buy Now")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex justify-end",
                                attrs: { cols: "6" },
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass:
                                      "rounded-lg primary d-flex flex-column align-center",
                                    attrs: { elevation: "0" },
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        height: "400",
                                        width: "200",
                                        src: require("../assets/images/girl.svg"),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inner-container only-heading-part" }, [
      _c(
        "div",
        { staticClass: "top-page-header only-heading page-header-with-banner" },
        [
          _c("div", { staticClass: "head-title" }, [
            _c("h1", [_vm._v("Subscription Plan")]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }