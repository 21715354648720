





















































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
export default Vue.extend({
  name: 'SubscriptionPlan',
  data: () => ({
    selectedItem: 1,
    items: [
      { text: 'Create Campaign', icon: 'mdi-arrow-right-circle-outline' },
      { text: 'Keyword Analysis', icon: 'mdi-arrow-right-circle-outline' },
      { text: 'Automation Rules', icon: 'mdi-arrow-right-circle-outline' },
      { text: 'Keyword Optimisation', icon: 'mdi-arrow-right-circle-outline' },
    ],
  }),

  methods: {
    openPortal() {
      var chargebeeInstance = Chargebee.getInstance();
      let cbPortal = chargebeeInstance.createChargebeePortal();
      //cbPortal.open();
      cbPortal.openSection({
        sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES
      })
    },
  }
});

// { text: '$500 with 5% of ASA monthly', icon: 'mdi-arrow-right-circle-outline' },
//         { text: '$2,000 with 4% of ASA monthly', icon: 'mdi-arrow-right-circle-outline' },
//         { text: '$5,000 with 2.5% of ASA monthly', icon: 'mdi-arrow-right-circle-outline' },
//         { text: '$10,000 with 2.5% of ASA monthly', icon: 'mdi-arrow-right-circle-outline'},
